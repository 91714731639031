import React, { createContext, useCallback, useMemo, useState } from "react";
import { EuiGlobalToastList } from "@elastic/eui";
import uniqueId from "lodash/uniqueId";

/**
 * @typedef {import('@elastic/eui').Toast} Toast
 * @typedef {(toast: Toast) => void} OnToast
 */

/** @type {React.Context<{ addToast: OnToast; removeToast: OnToast }>} */
export const ToastContext = createContext(undefined);

export function ToastProvider({ children }) {
  const [toasts, setToasts] = useState([]);

  /** @type {OnToast} */
  const addToast = useCallback((toast) => {
    setToasts((prevState) => [...prevState, { ...toast, id: uniqueId() }]);
  }, []);

  /** @type {OnToast} */
  const removeToast = useCallback((toast) => {
    setToasts((prevState) => prevState.filter((t) => t.id !== toast.id));
  }, []);

  const value = useMemo(
    () => ({ removeToast, addToast }),
    [addToast, removeToast],
  );

  return (
    <ToastContext.Provider value={value}>
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={removeToast}
        toastLifeTimeMs={2500}
      />
      {children}
    </ToastContext.Provider>
  );
}
